.resend-dataInput {
  height: 40px;
  margin-bottom: 15px;
}

.inactive-data-container {
  padding: 0 20px;
  max-height: 450px; /* You can adjust this based on the modal size */
  overflow-y: auto; /* Ensures vertical scrolling */
}

.reset-data-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.resend-footer {
  border-top: 1px solid #efefef;
  padding: 16px 16px 0 16px;
}

.image-selector {
  display: flex;
  width: 100%;
  gap: 32px;
  align-items: center;
}
.image-preview {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  object-fit: cover;
  background-color: white;
}

.image-bottom-container {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-top: 20px;
}

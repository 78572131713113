@import '../../../../../assets/globalStyles/variables.scss';
.device_container{


    width: 100%;
    border: 0.1px solid #EAEBF6;
    padding: 1rem;

    margin-right: 30px;

    padding-bottom: 0;
    border-radius: 10px;
    display: flex;
    gap: 5rem;
    margin-left: 12px;

}

.venue_container:last-child{
    margin-bottom: 0;
}
.additional__visitors{
    .btn {
        border: none !important;
    }
    
    .btn-link-primary{
        text-decoration: underline !important;
        padding: 0 !important;
    }

    .btn-link-primary:hover, .btn-link-primary:active{
        color: $primary;
        border: none;
    }

    .btn:disabled, .btn-link-primary:disabled {
        border: none !important;
        outline: none !important;
    }
}
.page-card {
    width: 100%;
    height: auto;
    border-radius: 12px; /* 12px / 16px */
    background: #fff;
    padding: 2rem; /* 32px / 16px */
    box-shadow:0px 0px 20px 0px #0000000A;;
}

.page-card-disabled{
    pointer-events: none;
    cursor: not-allowed !important;
    opacity: 0.7;
}
.info-card {
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 4px 6px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  padding: 24px;
  // width: 270px;
  flex: 1;
  max-width: 100%;

  &:hover {
    transform: scale(1.05);
  }

  transition: transform 0.3s ease-in-out;

  .info-card-detail {
    display: flex;
    flex-direction: column;

    .heading {
      font-weight: 600;
      font-size: 24px;
    }
  }
}


.venue_container{
    margin-top: 1.5rem;

    width: 100%;
    border: 0.1px solid #EAEBF6;
    padding: 1rem;

    padding-bottom: 0;
    border-radius: 10px;
    display: flex;
    gap: 5rem;

}

.venue_container:last-child{
    margin-bottom: 0;
}
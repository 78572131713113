.auth_screen {
    display: flex;
    height: 100vh;
    width: 100%;
    background: #f4f5ff;
    flex-direction: column;

    input {
      border-radius: 30px;
      border: 1px solid #CFD6DD;
      background-color: #fff;
      border-right: 1px solid #CFD6DD;
    }

    .input-group {
    .input-group-append{
        border-radius: 0px 30px 30px 0 !important;
        background: #fff;
    }
  }
}

.auth_nav{
  padding: 10px 60px;
  width : 100%;
  background-color: #fff !important;
  height: 72px;
  display: flex;
  align-items: center;
  border: 1px solid #EAEBF6;
}


.auth_image{
    flex: 50%;
    background-image: url('../../../assets/branding/login.webp');
    background-size: cover;
    background-repeat: no-repeat;
    height: 100vh;
    // box-shadow: inset linear-gradient(180deg, rgba(0, 0, 0, 0.50) 0%, rgba(0, 0, 0, 0.20) 20.43%, rgba(0, 0, 0, 0.00) 100%);
    // box-shadow: inset 0 0 0 100vmax rgba(0, 0, 0, 0.2), inset 0 0 0 0vmax rgba(0, 0, 0, 0.1);

}

.auth_content{
    width: 100vw;
    display: flex;
    align-items: center;
    // justify-content: center;
    // height: 100vh;
    flex-direction: column;
    background-color: white !important;
}

.auth_child {
    // flex: 1;
    // max-width: 490px;
    // min-width: 0;
    width: 32vw;
    box-sizing: border-box;
    background: white;
    padding: 34px;
    border-radius: 15px;
    scale: 0.94;
    margin-top: 40px;
    border: 1px solid #e0e0e0 !important;
    z-index: 1000;
  }

.logo_image {
  width: 167px !important;
  margin-top: 20px;
  margin-left: 9%;

}

.auth_brand_logo{
  // position: absolute;
  // top: 15px;
  // right: 15px;
  img{
    // margin: 15px 0;
    height: 45px;
    min-width: 200px;
    min-height: 45px;
    // max-height: 70px;
  }
}

.auth_left{
  position: absolute;
  bottom: 0px;
  left: 0;
}

.auth_right{
  position: absolute;
  bottom: 0;
  right: 0;
}

.bottom_line{
  position: absolute;
  bottom: 1px;
}

// .auth_brand_logo{
//   width: 100vw;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   img{
//     margin-top: 10px;
//     height: 32px;
//   }
// }

@media (max-width: 768px) {
    .auth_image {
      display: none; /* Hide the auth-image on smaller screens */
    }
  
    .auth_content {
      flex: 100%; /* Take up the entire width of the screen */
    }
  }

@media(min-width:200px) and (max-width:8000px){
  .auth_content{
    flex: 100% !important;
  }
  .auth_child {
    max-width: 490px;
    min-width: 0;
    width: 100% !important;
  }
}

@media(min-width:200px) and (max-width:1200px){
  .auth_left,.auth_right{
    display: none;
  }
}
@import '../../../assets/globalStyles/variables.scss';

.tag-input-container {
    padding: 0.75rem;
    min-height: 100px;
    max-height: auto;
    border-radius: 20px;
    background: $white;
    border: 1px solid #cfd6dd;
  }
  
  .tag-input {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    outline: none;
    border: none;
    background: $white;
  }
  
  .tag {
    display: flex;
    align-items: center;
    border-radius: 10px;
    border: 1px solid #cfd6dd;
    padding: 0.4375rem 0.625rem;
    background: #fff;
    color: var(--100, #1a1d1f);
  
    font-family: Inter;
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1rem; /* 114.286% */
    letter-spacing: -0.00875rem;
  }

  .tag-error{
    border: 0.8px solid $danger;
  }
  
  .remove-button {
    background: none;
    border: none;
    width: 1rem;
    height: 1rem;
    color: #6F767E;
    cursor: pointer;
    font-size: 0.6rem;
    margin-left: 4px;

    svg{
      width: 10px;
      line-height: normal;
      margin-top: -18px;
      fill: $danger;
    }
  }

  .remove-button:hover{
    background-color: $white;
  }
  
  .tag-input-feild {
    border: none !important;
    display: inline;
    // width: 10%;
    background-color: $white;
    padding-top: 0;
    padding-bottom: 0;
    height: 32px;
    font-size: 14px;
    padding-left: 5px;
  }

  .tag-input-feild:focus{
    background-color: $white;
  }
  

  .tag-close-btn-container{
    width: 14px;
    height: 14px;
  }
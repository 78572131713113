.recaptcha-terms-of-use {
    color: #6f767e;
    font-size: 13px;
    margin-top: 11px;
  }
  
  .recaptcha-terms-of-use {
    position: relative;
    text-align: left;
  }
  
  .recaptcha-terms-of-use--link-button {
    background-color: transparent;
    border: none;
    color: #3d9be9 !important;
    cursor: pointer;
    display: inline;
    font-family: inherit;
    font-size: inherit;
    padding: 0;
  }
  
  .recaptcha-terms-of-use--disclosure {
    margin-top: 10px;
  }
  
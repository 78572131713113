@import "../../../assets/globalStyles/variables.scss";

.search,
.outline-search {
  flex: 1;
  max-width: 320px;
}

.outline-search{
  max-width: 100%;
}

.search input:focus,
.outline-search input:focus {
  outline: none;
  box-shadow: none;
}

.search .input-group,
.search .input-group-prepend .input-group-text i,
.search .form-control::placeholder,
input::placeholder {
  color: $grey !important;
}

.outline-search {
  input::placeholder {
    color: $dark !important;
    font-size: 14px;
    font-weight: 600;
  }

  svg {
    path {
      fill: $dark;
    }
  }
}

::-webkit-input-placeholder {
  /* Safari, Chrome and Opera */
  color: $grey;
}

.search .input-group-prepend .input-group-text,
.outline-search .input-group-prepend .input-group-text {
  height: 48px;
  border-radius: 24px 0 0 24px;
  border: none;
  background-color: $lightestGrey;
}

.outline-search .input-group-prepend .input-group-text {
  border: 1px solid $lightGrey;
  background-color: white;
  border-right: 0;
}

.search .form-control,
.outline-search .form-control {
  border-radius: 24px;
  border: none;
  background-color: $lightestGrey;
  color: #6f767e;
}

.outline-search .form-control {
  border: 1px solid $lightGrey;
  background-color: white;
  border-left: 0;
}

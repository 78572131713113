@import "../../../assets/globalStyles/variables.scss";

// Mixins
@mixin flex-center($justify: center, $align: center) {
  display: flex;
  justify-content: $justify;
  align-items: $align;
}

.main_logo {
  object-fit: contain;
  aspect-ratio: 4 / 1;
  object-position: left center;
}

// Navbar Container
.navbar_container {
  position: sticky;
  z-index: 1;
  top: 0;
  width: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

// Reset List Styles
.navbar ul,
.switching_header ul {
  list-style: none;
  margin-bottom: 0;
  padding-left: 0;
}

// Anchor Styles
a {
  text-decoration: none !important;
  color: black;
  font-size: 18px;
}

// Navbar Styles
.navbar {
  width: 100%;
  max-width: 110rem;
  background-color: $white;
  padding: 10px 60px;
  @include flex-center(space-between, center);
  gap: 40px;
  height: 72px;
  z-index: 0;
  border-bottom: 0.1px solid #eaebf6;

  @media screen and (max-width: 1200px) {
    gap: 10px;
    padding: 10px 20px;
  }

  @media (min-width: 1233px) and (max-width: 1400px) {
    .navLink {
      /* Modify the navLink styles here */
      font-size: 11px;

      svg {
        width: 16px !important;
        height: 16px !important;
        font-size: 16px !important;
      }
    }
  }

  @media (min-width: 1233px) and (max-width: 1280px) {
    padding: 10px 35px !important;
    gap: 26px;
  }

  @media screen and (max-width: 1233px) {
    .hamburger_container {
      gap: 0.25rem;
    }
    .hamburger {
      display: block;
      cursor: pointer;

      .bar {
        background-color: $grey;
        width: 20px;
        height: 2px;
        margin: 4px;
        transition: 0.3s ease-in-out;
      }

      &.active .bar:nth-child(2) {
        opacity: 0;
      }

      &.active .bar:nth-child(1) {
        transform: translateY(5px) rotate(45deg);
      }

      &.active .bar:nth-child(3) {
        transform: translateY(-7px) rotate(-45deg);
      }
    }
  }

  .navLink {
    svg {
      height: 20px;
      width: 20px;
      font-size: 20px;
      margin-right: 5px !important;
      color: $white;
      fill: $white;
    }
  }

  .left_side {
    @include flex-center(flex-end, center);
    gap: 10px;

    i {
      color: $grey;
      font-size: 20px;
    }
  }
}

// Navbar Links
.navLink {
  @include flex-center(flex-start, center);
  color: #6f767e;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  padding: 10px 20px;
  transition: color 0.3s;

  &:hover {
    // background: $lightestGrey;
    color: $primary !important;
    // cursor: pointer;
    // path {
    //   fill: $primary !important;
    // }
  }
}

// Active Navbar Link
.active_nav {
  color: #ffffff !important;
  border-radius: 60px;
  background: $primary;
  transition: background 0.3s, color 0.3s;

  i {
    color: $primary;
  }

  svg {
    color: $primary;
    fill: $primary;
    margin-right: 7px;
  }
}

.unique_nav {
  border: 1px solid $primary;
  background: #fff !important;
  color: $primary !important;
  font-size: 14px;
  border-radius: 30px;
}

.active_nav {
  &:hover {
    color: $light !important;
  }
}

// Navbar Menu
.navMenu {
  @include flex-center(space-between, center);
  z-index: 1;

  @media screen and (max-width: 1233px) {
    position: absolute;
    flex-direction: column;
    top: 70px;
    left: 10px;
    text-align: start;
    transition: 0.7s ease-in-out;
    background-color: $dark;
    // padding: 10px;
    display: none;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.125);
    background-color: #fff;
    &.active {
      left: 10px;
      top: 80px;
      border-radius: 16px;
      display: block;
      path {
        fill: #1a1d1f;
      }
      a {
        color: #1a1d1f;
        gap: 5px;
      }
    }
  }
}

.navbar i {
  height: 20px;
  width: 20px;
  font-size: 20px;
  color: $dark;
  margin-right: 10px;
}

@media (min-width: 200px) and (max-width: 1230px) {
  .bar {
    display: block !important;
  }
  .active_nav {
    background-color: #f7f7f7;
    border-radius: 0;
    color: #1a1d1f !important;
  }
  .navLink {
    &:hover {
      color: #1a1d1f !important;
      background: $lightestGrey;
    }
  }
  .active_nav {
    color: $primary !important;
    path {
      fill: $primary !important;
    }
    &:hover {
      color: $primary !important;
    }
  }
  .navMenu {
    padding: 10px 0;
  }
}

.color-picker-container {
    position: relative;
    width: 100%;
    font-family: Arial, sans-serif;

    .color-input {
      border: none !important;
    }

  }

  .color-data-container{
    display: flex;
    align-items: center;
    border: 1px solid #cfd6dd;
    border-radius: 20px;
  }
  
  .selected-color-preview {
    width: 15%;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 1px solid #cfd6dd !important;

    cursor: pointer;
    font-size: 16px;
    border-radius: 20px 0 0  20px;
  }
  
  .picker-popup {
    position: absolute;
    top: 50px;
    left: 0;
    width: 100%;
    background-color: white;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    padding: 20px;
    border-radius: 10px;
  }
  
  .color-grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 5px;
    margin-bottom: 20px;
  }
  
  .color-box {
    width: 30px;
    height: 30px;
    cursor: pointer;
    border: 1px solid transparent;
    border-radius: 4px;
  }
  
  .color-box.selected {
    border: 2px solid #000;
  }
  
  .action-buttons {
    display: flex;
    justify-content: space-between;
  }
  
  button {
    padding: 8px 15px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #f0f0f0;
  }
  
  .sketch-picker{
    position: absolute;
    z-index: 1000;
    width: 240px !important;
    input {
      border-radius: 0 ;
      height: max-content;
      background-color: #ffffff;
    }
    outline: none;
  } 

  // .color-input-container {
  //   margin-top: 10px;
  // }
  
  // .color-input {
  //   width: 100%;
  //   padding: 8px;
  //   border: 1px solid #ccc;
  //   border-radius: 4px;
  //   font-size: 14px;
  // }
.checkbox_container {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: min-content;
  position: relative;
  label {
    width: max-content;
    margin-bottom: 0;
  }
  input {
    width: 20px;
    height: 20px;
  }
}

.small_multi_select_filter_wrapper {
  .labelWrap {
    height: 37px;
    padding: 11px 13px;

    i {
      margin-top: 0;
      margin-right: -2px;
    }
  }

  .title {
    margin-bottom: 0;
    line-height: 14px;
    font-size: 13px !important;
  }
}

.title {
  font-family: Inter;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  min-width: max-content;
  margin-bottom: 0;
  font-size: 15px;
}

.labelWrap {
  border: 1px solid #ced5de;
  // width: 100%;
  height: 48px;
  border-radius: 30px;
  padding: 11px 17px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  position: relative;
  min-width: max-content;

  i {
    margin-top: 2px;
  }
}

.activeLabel {
  border: 1px solid #6b66da;

  // &::after {
  //   content: "";
  //   width: 10px;
  //   height: 10px;
  //   border-radius: 50%;
  //   background: #6b66da;
  //   position: absolute;
  //   right: 45px;
  // }
}

.indicator_dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #6b66da;
    position: absolute;
    right: 45px;

}



// .activeLabel::after {
//   position: absolute;
//   top: -3px; /* Adjust the top position as needed */
//   right: -1px; /* Adjust the right position as needed */
//   background-color: #ff6a55; /* Set the background color as needed */
//   color: white; /* Set the text color */
//   font-size: 8px; /* Adjust the font size as needed */
//   border-radius: 50%; /* Make it a circle */
//   width: max-content;
//   min-width: 12px;
//   min-height: 12px;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   font-weight: 500;
//   content: ""; /* Add this line to ensure the pseudo-element is generated */
// }

.positionStyles {
  position: absolute;
  // top: 50px;
  // bottom: 0;
  background-color: white;
  border: 1px solid #efefef;
  border-radius: 16px;
  padding: 1rem 1rem 0 1rem ;
  z-index: 1;
  max-height: 350px;
  // width: 100%;
  margin-top: 10px;
  box-shadow: 0px 4px 10px 0px #00000029;

  // margin-left: 15px;
  // overflow-y: auto;
  // min-width: 245px;
  // max-width: 245px;
  input {
    width: 100%;
  }
}

.dataContainer {
  // padding-top: 5px;
  max-height: 270px;
  overflow-y: scroll;
}

.bottomContainer {
  border-top: 1px solid #efefef;
  display: flex;
  justify-content: end;
  gap: 20px;
  padding: 10px;
  cursor: pointer;
  align-items: center;
}

.customBtn {
  height: 40px !important;
  padding: 0 18px !important;
  font-size: 15px;
  font-weight: 500 !important;
}

.dataInput {
  height: 40px;
  margin-bottom: 15px;
}
.multi_select_filter_wrapper {
  min-width: max-content;
}

.checkmark {
  top: 0 !important;
}
.check-container {
  font-size: 16px;
  display: flex;
  align-items: center;
  width: 100%;
  overflow-wrap: wrap;
  overflow: hidden;
}

@import "../../../assets/globalStyles/variables.scss";

.time-input-component {
  svg {
    fill: $dark !important;
  }

  ::placeholder {
    color: black !important;
    font-weight: 590;
  }
  display: contents;
  width: 100%;

}


.Mui-selected{
  background-color: $primary !important;
}

.MuiButtonBase-root.MuiButton-root{
  color: $primary !important;
}

.time-input-component:focus-within,
.error-container:focus-within {
  background-color: $white;
  border: 1px solid $primary;
  outline: none;
  display: grid;
  overflow: hidden;
  border-radius: 20px;

  .error-container:focus-within{
    border: 1px solid $danger;
  }

  input {
    &:focus {
      border: none;
    }
  }

  div {
    background: $white !important;
  }
}

.error-container {
  .MuiInputBase-root {
    border: 1px solid $danger !important;
  }
}

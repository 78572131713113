.filter-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  flex: 1;
  gap: 15px;

  #reset{
    max-width: 150px;
  }
}

.filter-btns {
  display: flex;
  gap: 10px;
  flex-shrink: 0;
}

@media (min-width: 200px) and (max-width: 900px) {
  .filter-container {
    grid-template-columns: repeat(2, 3fr);
  }
}

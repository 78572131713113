$dark : #1A1D1F;
.not-found{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: auto;
    margin-top: 20vh;
    flex-direction: column;

    .header{
        font-weight: 700;
        font-size: 70px;
        color: $dark;
        line-height: 48px;;
    }

    .subheader{
        margin-top: 15px;
        color: $dark;
        font-size: 20px;
    }

    .link{
        margin-top: 12px;

        font-size: 14px;
        a{
            font-size: 14px;
            text-decoration: underline !important;
        }
    }
}
.dashboard__info__modal{
    display: flex;
    width: 100%;
    gap: 1rem;
    .details{

        .list-group-item-text{
            margin-bottom: 0;
        }
        .list-layout h6{
            display: inline-block;
        }
        flex: 1
        ;
    }
}


.dropzone{
    border: 1px dashed #C0C4C9 ;
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    i {
        font-size: 24px;
        color: #6F767E;
    }
}
.select__placeholder{
    font-weight: 500;
}
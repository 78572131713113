@import "../../../assets/globalStyles/variables.scss";

.dashboard {
  background-color: $bgLight;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dashboard_body {
  padding: 20px 60px 0;
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 110rem;
}

.dashboard_plan_expiry {
  width: 100%;
  display: flex;
  justify-content: center;
  font-weight: 500;
  color: white;
  padding: 10px 0;
  background: #6b66da;
}

@media (min-width: 200px) and (max-width: 1230px) {
  .dashboard_body {
    padding: 15px 25px 0;
  }
}

@media (min-width: 1233px) and (max-width: 1280px) {
  .dashboard_body {
    padding: 20px 35px 0;
  }
}

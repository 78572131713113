@import "variables";
.btn {
  margin-top: 0;
  border-radius: 24px !important;
  height: 48px !important;
  display: flex;
  align-items: center !important;
  justify-content: center !important;
  padding: 0 24px !important;
  font-weight: 600 !important;
  min-width: max-content;
}

.btn-primary {
  background-color: $primary;
  border: none !important;
}

.btn-sm {
  height: 40px !important;
  padding: 0 20px !important;
}

.btn-xs {
  height: 35px !important;
  padding: 2px 16px !important;
  font-size: 13px;
}

.btn-primary:hover,
.btn-primary:active {
  background-color: $primary !important;
}

.btn-primary:disabled {
  background-color: #9a9fa5 !important;
  opacity: 1 !important;
}

.btn-outline-dark {
  border: 1px solid var(--50, #c0c4c9);
  color: $dark;
}

.btn-outline-primary {
  border: 1px solid $primary !important;
  color: $primary !important;

  svg {
    fill: $primary;
  }
}

.btn-outline-primary:hover {
  color: #fff !important;
  background-color: $primary;

  svg {
    fill: $white;
  }
}

.btn-dark {
  background-color: $dark !important;
  color: #fff !important;
}

.btn-outline-dark:hover,
.btn-outline-dark:active {
  background-color: $light !important;
  border: 1px solid $grey0 !important;
  color: $dark !important;
}

.btn-outline-danger {
  // border: 1px solid $danger !important;
  color: $danger;

  svg {
    fill: $danger;
  }
}

.btn-outline-danger:hover,
.btn-outline-danger:active {
  background-color: $danger;
  // border: 1px solid $danger;
  color: $white;

  svg {
    fill: #fff;
  }
}
.btn-outline-primary {
  border: 1px solid $primary;
  color: $primary;
}

.btn-outline-primary:hover,
.btn-outline-primary:active {
  background-color: $primary;
  border: 1px solid $primary;
  color: $white;
}

.responsive-btn {
}

.w-60 {
  width: 60% !important;
}

.w-80 {
  width: 80% !important;
}

@media screen and (max-width: 400px) {
  .responsive-btn {
    width: 100%;
  }
}

.rich-text-editor-container {
  ul {
    li {
      cursor: text !important;
      display: flex;
    }
    li::before {
      content: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" id="Layer_1" data-name="Layer 1" viewBox="0 0 24 24" width="14" height="14"><path d="M12,17c-2.76,0-5-2.24-5-5s2.24-5,5-5,5,2.24,5,5-2.24,5-5,5Z"/></svg>');
      margin-right: 8px;
      margin-top: 2.5px;
      // Adjust the spacing between the icon and the conten
    }
  }

  h4 {
    font-size: 1em;
    text-align: justify;
  }
}

.no-pointer-events {
  pointer-events: none;
}
.space-between {
  display: flex;
  justify-content: space-between;
}

.MuiCircularProgress-circle {
  stroke: $primary !important;
}

.table-icons-container {
  display: flex;
  align-items: center;
  height: 100%;
  gap: 10px;

  svg {
    cursor: pointer;
    fill: #4e5660;
  }
}

.w-70 {
  width: 70%;
}

.w-60 {
  width: 60%;
}

.w-50 {
  width: 50%;
}

.w-40 {
  width: 40%;
}

.w-20 {
  width: 20%;
}

.w-30 {
  width: 30%;
}

.value {
  color: $grey;
}

.view-container {
  label {
    margin-bottom: 0 !important;
  }
}

.checkbox_container {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: min-content;
  position: relative;
  label {
    width: max-content;
    margin-bottom: 0;
  }
  input {
    width: 20px;
    height: 20px;
  }
}

.mb-10 {
  margin-bottom: 20px;
}

.table-div::-webkit-scrollbar {
  display: none;
}

.gap-7 {
  gap: 5rem;
}

span {
  font-family: Inter;
  font-weight: 500;
}

.flex-1 {
  flex: 1;
}

.btn-link a {
  cursor: pointer;
  svg {
    width: 15px;
    height: 15px;
    margin-right: 8px;
  }
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
}

.btn-link-primary,
.btn-link-primary:hover {
  color: $primary;

  svg {
    fill: $primary;
  }
}

a {
  text-decoration: none !important;
}

.MuiList-root {
  &::after {
    display: none !important;
  }
}

.float-right {
  float: right !important;
}

.field_array_detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  p {
    margin-bottom: 0;
  }

  .remove {
    color: $danger;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
  }
}


.remove {
  color: $danger;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.table_with_scroll {
  .MuiDataGrid-root {
    .MuiDataGrid-virtualScroller {
      scrollbar-width: thin !important;
    }
  }
}

.tab-employee-responsive-block {
  display: none !important;
}
@media (min-width: 200px) and (max-width: 1230px) {
  .tab-employee-responsive-block {
    display: flex !important;
    align-items: center;
  }
  .tab-employee-responsive-none {
    display: none !important;
  }
  .header-tab-between {
    width: 100%;
    justify-content: space-between;
  }
  .responsive-tab-gap {
    gap: 10px !important;
  }
  .MuiDataGrid-root {
    .MuiDataGrid-virtualScrollerContent,
    .MuiDataGrid-virtualScroller,
    .no-content-card {
      transform: rotateX(180deg);
    }
  }
  div:is(.pagination-component) {
    width: auto !important;
  }
}

@media (min-width : 450px) and (max-width : 780px){
  .remove {
    margin-top: 0 !important;
  }
}

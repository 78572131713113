.activity-detail-container{
    border: 1px solid #CED5DE;
    border-radius: 16px;


    .activity-content{
    display: flex;
    flex-direction: row;
    padding: 1rem;
    gap: 1rem;

    .activity-info{
        display: flex;
        width: 100%;

        .info-right{
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            .action{
                font-size: 20px;
                font-weight: 700;
                color: #1E273B;
                font-family: 'Inter';
            }

            .action-description{
                font-size: 14px;
                font-weight: 400;
            }
        }

        .action-detail{
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .action-time{
            font-size: 14px;
            font-weight: 500;
        }

        .view-more {
            color: var(--PA-Primary, #3D9BE9);
            font-family: Inter;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 16px; /* 114.286% */
            letter-spacing: -0.14px;
            text-align: left;
            cursor: pointer;
          }
    }
    }

    .expanded-info{
        margin-top: 1rem;
      }

      .expanded-table {
        width: 100%;
        border-collapse: collapse;
      }

      .expanded-table th{
        color: #6F767E;
        font-weight: 500;
      }

      .expanded-table td{
        color: #1E273B;
        font-weight: 500;
      }
      
      .expanded-table th, .expanded-table td {
        text-align: left;
        padding: 1rem;

      }

      .expanded-table tr{
        border-bottom: 1px solid #CED5DE;
      }

    }
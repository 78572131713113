$white: #ffffff;
$light: #efefef;
$lightestGrey : #f4f4f4;
$bgLight : #F4F5FF;
$bgDark : #111112;
$grey0 : #c0c4c9;
$grey: #6f767e;
$lightGrey: #ced5de;
$dark: #1a1d1f;
$green: #83bf6e;
$darkGreen: #537846;
$danger: #be0f00;
$yellow: #ffa700;
$primary: #6B66DA;

.fw-bold {
    font-weight: 500 !important;
}

.text-primary {
    color: $primary !important;
}

.text-darkGreen{
    color: $darkGreen !important;
}

.text-success {
    color: $green !important;
}

.text-dark {
    color: $dark !important;
}

.text-error{
    color:$danger !important;
}

.text-warning {
    color: $yellow !important ;
}

.text-grey {
    color: $grey !important;
}
@import "../../../../assets/globalStyles/variables.scss";

.toggleBtn {
  transition: all 100ms;
  background-color: #ffffff;
  border-color: hsl(0, 0%, 80%);
  border-radius: 6px;
  border-style: solid;
  border-width: 1px;
  border: 1px solid #e5e5ea !important;
  min-width: 150px;
  height: 40px;
  margin-right: 10px;
  padding: 10px;
  transition: border-color ease-in-out 0.15s;
  display: flex;
  justify-content: space-around;
  gap: 10px;

  span {
    font-size: 14px;
    line-height: normal;
  }

  img {
    width: 22px;
    height: 22px !important;
  }
}

.toggleBtn:hover,
.toggleBtn:focus,
.toggleBtn:active {
  border: 1px solid $primary !important;
  outline: 1px solid $bgLight;
}

.label_switch {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #6f767e;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  padding: 10px 10px 10px 0px;
  transition: color 0.3s;
}

.log-div-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 1rem;
    margin-top: 1rem;
}

.log-div {
    padding: 1rem;
    border: 1px solid #efefef;
    border-radius: 12px;
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.0392156863);
}

/* Media query for smaller screens */
@media (max-width: 600px) {
    .log-div-container {
        grid-template-columns: 1fr;
    }
}

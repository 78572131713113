.view_info_container {
    display: flex;
    margin-bottom: 1.5rem;
    align-items: start;
  
    .label {
      flex: 0 0 150px;
      margin-right: 10px;
  
      color: #6f767e;
      font-weight: 500;
      font-size: 14px;
    }
  
    .data {
      flex: 1;
      font-weight: 500;
      color: #1a1d1f;
      font-size: 14px;
      display: flex;
      align-items: start;

      .colon {
        display: inline;
        color: #6f767e;
        font-weight: 500;
        font-size: 14px;
        margin-right: 1.5rem;
      }

      ul{
        padding-left: 0;
        margin-bottom: 0;
      }

      li{
        list-style: none;
      }
    }
}
.form_footer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.form_footer button {
  flex: 1;
}

// Password Strength
.password_strength {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.condition_wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}

.condition {
  width: 12px;
  height: 12px;
  display: inline-block;
  border-radius: 50%;
  margin-right: 5px;
  background-color: #ccd1df;
  transition: background-color 0.3s;
}

.condition.met {
  background-color: #83bf6e !important;
}

.conditions_not_met .condition {
  background-color: #be0f00;
}

.condition_heading {
  color: #1e273b;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  margin-top: 20px;
  margin-bottom: 0;
}

.condition_description {
  color: #82899a;
  font-size: 14px;
  font-weight: 500;
  line-height: 30px; /* 214.286% */
}

.MuiDataGrid-root {
  .MuiDataGrid-virtualScroller::-webkit-scrollbar {
    height: 10px;
    display: block !important;
  }
  .MuiDataGrid-virtualScroller {
    scrollbar-width: thin !important;
  }
}
.table-div {
  .MuiDataGrid-root {
    .MuiDataGrid-virtualScroller::-webkit-scrollbar {
      height: 10px;
      display: block !important;
    }
    div:is(.MuiDataGrid-virtualScroller) {
      scrollbar-width: none !important;
    }
  }
}

@media (min-width: 200px) and (max-width: 1230px) {
  .col-sm {
    flex: auto !important;
  }
}

.image_modal{
    position: fixed;
    z-index: 100000;
    padding-top: 100px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.9);
}

.close {
    position: absolute;
    top: 15px;
    right: 35px;
    color: #f1f1f1;
    font-size: 40px;
    font-weight: bold;
    transition: 0.3s;
    cursor: pointer;
}

.modal_content {
    margin: auto;
    display: block;
    width: 80%;
    max-width: 400px;
    animation-name: zoom;
    animation-duration: 0.6s;
    border-radius: 0.25rem;
}
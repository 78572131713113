@import "../../../assets/globalStyles/variables.scss";

.custom-filter-container {
  position: relative;
  /* Set to relative to control the positioning of its children */
  cursor: pointer;
}

.filter-header {
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;

  .header {
    color: $dark;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0;

    i {
      font-size: 14px;
    }
  }
}

.filter-box {
  top: calc(100% + 12px);
  display: flex;
  position: absolute;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 16px 0px rgba(0, 0, 0, 0.06);
  border: 1px solid var(--30, #efefef);
  flex-direction: column;
  z-index: 100;
  right: 0;
}

.filter-data-box {
  display: flex;
  flex-direction: row;
  // min-height: 300px;
  max-height: 300px;
  /* Set your desired maximum height */
  // overflow-y: auto;
  /* Add this to enable scrolling when the content exceeds max-height */
}

.filter-key-box {
  display: flex;
  flex-direction: column;
  min-width: 170px;
  border-right: 1px solid #efefef;
}

.filter-key {
  padding: 16px 20px;
  // justify-content: center;
  align-items: center;
  color: $dark;
  font-family: Inter;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  display: flex;
  justify-content: space-between;
}

.filter-key:hover,
.filter-key:focus,
.filter-key:active,
.active-key {
  background-color: $light;
}

.filters-column {
  padding: 15px;
  // padding-right: 70px;
  padding-top: 0;

  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  min-width: 170px;
  width: 240px;
  min-height: 240px;
  height: auto;
  overflow-y: auto;
}

label {
  font-size: 14px !important;
}

.dot-primary {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  margin: 0 7px;
  background-color: $primary;
  display: inline-block;
}

.label-checkobox-text{
  width: 100% !important;
text-overflow: ellipsis;
text-wrap: nowrap;
overflow: hidden;
}

@media (min-width:200px) and (max-width:480px){
  .filter-mobile-container{
    .filter-box{
      scale: 0.7;
      top: calc(100% - 41px);
      right: -57px;
    }

  }
}

.active-filter{
  border: 1px solid $primary !important;
}

.active-filter, .active-filter:hover{
  border: 1px solid $primary !important;
  background-color: $bgLight !important;
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&family=Plus+Jakarta+Sans:wght@200;300;400;700;800&display=swap");
@import "typography.scss";
@import "variables.scss";
@import 'inputs';
@import 'custom';
@import 'forms';

:root {
  font-family: "Inter", sans-serif;
  line-height: 1.5;
  font-synthesis: none;
  -webkit-text-size-adjust: 100%;
  scroll-behavior: auto;
}

::-webkit-scrollbar {
  background: transparent;
  width: 7px !important;
  scroll-behavior: smooth;
  border-radius: 12px;
  height: 2px;
}

::-webkit-scrollbar-thumb {
  background: #c5c5c5;
  border-radius: 20px;
  height: 30px;
}

div::-webkit-scrollbar-thumb {
  background: #bbb; /* Adjust the color as needed */
  border-radius: 10px;
  height: 10px; /* Adjust the height as needed */
}

.cursor-pointer{
  cursor: pointer;
}

// Toast React Hot Toast Customization
.go2344853693:after {
  border-color: #1e273b !important ;
}

.go2534082608:after,
.go2534082608:before {
  background: rgb(190, 15, 0) !important;
}

.go2344853693:after {
  border-color: #83bf6e !important;
}

.go2344853693,
.go2534082608 {
  background-color: #fff !important;
}

.text-ecp{
  text-overflow: ellipsis;
    max-width: 160px;
    overflow: hidden;
    white-space: nowrap;
}

.grecaptcha-badge{
  display: none !important;
}

.line-truncate {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.cursor-pointer{
  cursor: pointer;
}
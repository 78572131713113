@import '../../../assets/globalStyles/variables.scss';

// Date Picker 
.MuiInputBase-root,
.MuiOutlinedInput-root {
  background-color: #fff !important;
  height: 48px;
  font-size: 14px !important;
  border-radius: 20px !important;
  border: 1px solid #CFD6DD !important;
  padding: 0 6px;
}

.MuiOutlinedInput-notchedOutline {
  border: none !important;
  color: #1A1D1F;
}

.calender-input-component{
  ::placeholder {
    color: black !important;
    font-weight: 500;
  }

  .Mui-disabled{
    -webkit-text-fill-color: #1A1D1F !important;

    fill: #1A1D1F !important;
  }

}

.calender-input-component, .error-container {
  display: contents;
}

.calender-input-component:focus-within, .error-container:focus-within {
  background-color: $white;
  border: 1px solid $primary;
  outline: none;
  display: grid;
  overflow: hidden;
  border-radius: 20px;


  input {
    &:focus {
      border: none;
    }
  }

  div {
    background: $white!important;
  }
}

.error-container:focus-within {
  border: 1px solid $danger !important;
}

.error-container{

  .MuiInputBase-root{
  border: 1px solid $danger !important;
  }
}

.calender-input-component {
  input {
    font-weight: 500 !important;
    color: $dark !important;
  }

  button {
    color: $dark;
    width: 45px !important;
  }

  :first-child {
    width: 100%;
  }
}